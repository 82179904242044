import logo from "./assets/richa-ai.jpg";

function App() {
  return (
    <div className="main-container">
      <img className="logo" src={logo} alt="Richa AI Logo" />
      <span className="richaai">richa | ai</span>
      <p className="coming-soon" align="center">coming Soon</p>
    </div>
  );
}

export default App;
